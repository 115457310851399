import {
  DashboardAction,
  DashboardType,
  IDashboardState,
} from "../types/dashboardTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export default (
  state: IDashboardState = initialState,
  { type, payload }: DashboardAction
) => {
  switch (type) {
    case DashboardType.DASHBOARD_PENDING:
      return { ...state, ...payload, error: null };
    case DashboardType.DASHBOARD_SUCCESS:
      return { ...state, ...payload };
    case DashboardType.DASHBOARD_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
