import {
  ClearingAction,
  ClearingType,
  IClearingState,
} from "../types/clearingAssetsTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  dataDetail: null,
  isLoadingDetail: false,
  isLoadingApprove: false,
  isLoadingInsert: false,
  isLoadingReject: false,
  isLoadingCreate: false,
  isLoadingPending: false,
};

export default (
  state: IClearingState = initialState,
  { type, payload }: ClearingAction
) => {
  switch (type) {
    case ClearingType.CLEARING_PENDING:
      return { ...state, ...payload, error: null };
    case ClearingType.CLEARING_SUCCESS:
      return { ...state, ...payload };
    case ClearingType.CLEARING_ERROR:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};
