import { AxiosError } from "axios";
import { IMiddleResponse } from "./middlewareTypes";

export enum ClearingType {
  CLEARING_PENDING = "CLEARING_PENDING",
  CLEARING_SUCCESS = "CLEARING_SUCCESS",
  CLEARING_ERROR = "CLEARING_ERROR",
}

export interface IParamClearing {
  Page: number;
  TotalShow: number;
  KeyWord: string;
  DateStart?: string;
  DateEnd?: string;
}

export interface IParamClearingDetail {
  Id?: string;
  TrxId: number;
  ClientId: number;
}

export interface IClearingDataMaster {
  ClearingAssetId: string;
  ClientId: number;
  TransactionId: number;
  TransactionReceipt: string;
  TransactionDate: string;
  Amount: number;
  PahTypeName: string;
  ClearingAssetStatus: number;
  ClearingAssetStatusName: string;
  ClearingAssetApprovalStatus: number;
  ClearingAssetApprovalStatusName: string;
  ClearingAssetDate: string;
  ClearingAssetActionType: string;
  ClearingAssetActionTypeName: string;
}

export interface IClearingResponse {
  code: number;
  status: string;
  message: string;
  data: any;
  paginate: {
    page: number;
    total_page: number;
    total_record: number;
    limit: number;
  };
}

export interface IClearingResponseMaster {
  Code: number;
  Status: string;
  Message: string;
  Data: IClearingDataMaster;
  Page: number;
  TotalPage: number;
  TotalShow: number;
}

export interface ICommitClearing {
  ClientId: number;
  TrxId: number;
  ActionId: number;
}

export interface IApprovalData {
  Note: string;
  Id: string;
  Action: number;
}

export interface IMiddlewareBodyClearingIn {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "clearing-asset";
  Action:
    | "get-list-clearing-asset"
    | "get-detail"
    | "approve-clearing-asset"
    | "reject-clearing-asset"
    | "get-list-asset-pending"
    | "insert-clearing-asset";
  ResourceType: "data";
  Resource: "data";
  ResourceId: "data";
  UserId: string;
  Date: string;
  ScopePlatform: "private";
  Platform: "qoin-apps";
  Data:
    | IParamClearing
    | IParamCreate
    | IParamClearingDetail
    | IParamApprovalClearing
    | ICommitClearing
    | IApprovalData;
}

export interface IParamApprovalClearing {
  Id: string;
  Note: string;
}

export interface IDetailsRowExcel {
  Id: string;
  DisbursId: string;
  PhoneNumber: string;
  Name: string;
  Email: string;
  Amount: number;
  Fee: number;
  NetAmount: number;
  NoteReq: string;
  NoteReject: string;
  ApprovalStatus: number;
  TopUpStatus: number;
  IsError: boolean;
  ErrorMessage: string;
}

export interface IParamCreate {
  Id: string;
  ClientId: number;
  CategoryId: string;
  AccountHolderSource: string;
  Type: number;
  FileName: string;
  ReferenceNumber: string;
  TotalTrx: number;
  TotalTrxAmount: number;
  ClearingAmount: string;
  IsRecurring: boolean;
  Details: IDetailsRowExcel[];
  Schedule?: {
    ScheduleType: string;
    ScheduleTime: {
      Time: string;
    };
  };
}

export interface IMiddlewareBodyClearingOut {
  TicketId: string;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "clearing-asset";
  Action:
    | "get-list-clearing-asset"
    | "get-detail"
    | "approve-clearing-asset"
    | "reject-clearing-asset"
    | "get-list-asset-pending"
    | "insert-clearing-asset";
}

export interface ITemplateResultResp {
  Data: {
    Base64Value: string;
    FileName: string;
  };
  Ticket: string;
  Code: number;
  Status: string;
  Errors: string;
  RequestId: string;
  Command: string;
  ModuleName: string;
}

export interface IMiddlewareResponseClearingOut {
  ClientId: number;
  Subject: "ewallet";
  SubjectType: "web-qoin";
  SubjectIdentifier: "clearing-asset";
  Action: "get-list-clearing-asset";
  ResourceType: null;
  Resource: null;
  ResourceId: null;
  UserId: string;
  Date: string;
  Result: IClearingResponseMaster;
  Status?: string;
  StatusCode?: number;
}

interface GetClearingAction {
  type: ClearingType.CLEARING_SUCCESS;
  payload: {
    data: IMiddlewareResponseClearingOut | IMiddleResponse | null;
  };
}

interface SetLoadingClearingAction {
  type: ClearingType.CLEARING_PENDING;
  payload?: {
    data: any;
  };
}

interface SetErrorClearingAction {
  type: ClearingType.CLEARING_ERROR;
  payload: {
    error: AxiosError;
  };
}

export interface IClearingState {
  data: IMiddlewareResponseClearingOut | IMiddleResponse;
  isLoading: boolean;
  error: AxiosError;
  dataDetail: any;
  dataPending: any;
  isLoadingDetail: boolean;
  isLoadingApprove: boolean;
  isLoadingInsert: boolean;
  isLoadingReject: boolean;
  isLoadingCreate: boolean;
  isLoadingPending: boolean;
}

export type ClearingAction =
  | GetClearingAction
  | SetLoadingClearingAction
  | SetErrorClearingAction;
